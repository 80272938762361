import { CommonGamesSearch, IGamesSearchProps } from '@/features/recreation/component/games-search'
import styles from './index.module.css'

export default function GamesSearchOk(props: IGamesSearchProps) {
  return (
    <CommonGamesSearch
      {...props}
      showNoDataImg
      showHotSearch
      showDelHistoryIcon
      inputClass={styles['games-search-ok-input']}
      popupClass={styles['games-search-ok-wrap']}
    />
  )
}
