import { t } from '@lingui/macro'
import { ReactNode, memo, useEffect, useRef, useState } from 'react'
import Icon from '@/components/icon'
import { link } from '@/helper/link'
import { Button, Progress, Select, Spin, Image } from '@nbit/arco'
import classNames from 'classnames'
import { ThemeEnum } from '@nbit/chart-utils'
import { useCommonStore } from '@/store/common'
import { RaHallGameCardTypeProps, GetGameInfoTypeProps } from '@/typings/api/game'
import NoDataImage from '@/components/no-data-image'
import { Type } from '@/components/lazy-image'
import { I18nsMap } from '@/constants/i18n'
import { RaGameTypeEnum } from '@/constants/game'
import GameCell from '@/features/recreation/theme/components/game-cell'
import GamesSearch from '@/features/recreation/theme/components/games-search'
import styles from './index.module.css'
import SupplierGame from '../supplier-game'

interface IAllGamesProps {
  /** 是否展示搜索组件 */
  showSearch?: boolean
  /** 是否展示标题栏组件 */
  showTitle?: boolean
  /** 是否展示语言筛选 */
  showLanguage?: boolean
  /** 语言类型 */
  languageCode?: string | undefined
  /** 标题栏展示类型 */
  titleType?: TitleTypeEnum
  /** 是否展示底部栏 */
  showFooter?: boolean
  /** 底部栏展示类型 */
  footerType?: PaginationTypeEnum
  /** 是否展示模块 loading */
  isLoading?: boolean
  /** 是否展示在玩人数 默认 false 展示 */
  noShowOnlineUsers?: boolean
  /** 搜索 */
  onSearch?: (v) => void
  /** 加载更多 */
  onLoadMore?: () => void
  /** 上一页 */
  onLoadPrev?: () => void
  /** 下一页 */
  onLoadNext?: () => void
  /** 加载剩余全部 */
  onLoadAll?: () => void
  isImage?: boolean
  type?: string
  total?: number
  data?: RaHallGameCardTypeProps[]
  loading?: boolean
  onEnterChange?: (v) => void
  title?: string
  titleIcon?: string
  isShowButton?: boolean // 是否显示加载更多按钮
  supplierInfo?: GetGameInfoTypeProps[]
  sortValue?: string // 当前选择的排序
  onSortChange?: (v) => void // 排序
  onSupplierChange?: (v) => void
  onAllClearChange?: () => void
  renderNoImage?: ReactNode
  supplierCurrentSelect?: GetGameInfoTypeProps[]
  /** 选择语言 */
  onLanguageChange?: (v) => void
}

/** 标题栏类型 */
export enum TitleTypeEnum {
  /** 分类 */
  category = 'category',
  /** 筛选 - 所有 */
  filterAll = 'filterAll',
  /** 筛选 - 游戏供应商 */
  filterSupplier = 'filterSupplier',
}

/** 分页加载类型 */
export enum PaginationTypeEnum {
  /** 查看全部 */
  all = 'all',
  /** 加载更多 */
  more = 'more',
  /** 分页 */
  pagination = 'pagination',
}

function AllGames(props: IAllGamesProps) {
  const {
    type,
    total,
    data,
    title,
    isImage,
    titleIcon,
    loading,
    sortValue,
    isShowButton = true,
    isLoading = false,
    showSearch = true,
    showTitle = true,
    noShowOnlineUsers = false,
    showLanguage = false,
    titleType = TitleTypeEnum.filterSupplier,
    showFooter = true,
    footerType = PaginationTypeEnum.pagination,
    supplierInfo,
    renderNoImage,
    languageCode,
    supplierCurrentSelect,
    onLoadAll,
    onLoadMore,
    onLoadPrev,
    onLoadNext,
    onSortChange,
    onEnterChange,
    onSupplierChange,
    onAllClearChange,
    onLanguageChange,
  } = props || {}
  const options = [
    { name: 'A-Z', value: '0' },
    { name: 'Z-A', value: '1' },
    { name: t`features_home_component_all_games_index_dg7eo9it6l`, value: '' },
  ]

  const { theme } = useCommonStore()
  const supplierSelectRef = useRef<any>(null)
  const [supplierSelect, setSupplierSelect] = useState<GetGameInfoTypeProps[]>([])

  const onAllClear = () => {
    setSupplierSelect([])
    onAllClearChange?.()
  }

  /**
   * 下拉框打开时限制页面滚动
   */
  const onVisibleChange = (visible: boolean) => {
    const element: HTMLElement | null = (document.querySelectorAll('#pageContentRight')?.[0] as HTMLElement) || null

    element.style.overflowY = visible ? 'hidden' : 'auto'
  }

  const onRenderLanguage = () => {
    return (
      <Select
        value={languageCode}
        bordered={false}
        trigger="hover"
        triggerProps={{
          position: 'bottom',
          autoFitPosition: false,
        }}
        prefix={<Icon name="icon_language_sidebar" className="select-prefix" />}
        arrowIcon={<Icon name="icon_arrow_down" className="text-xs text-icon_color_02" />}
        placeholder={t`features_home_index_uyddxpkxoh`}
        className="header-select language"
        dropdownMenuClassName={classNames(styles['header-select-dropdown'], 'supplier', 'h-80')}
        dropdownRender={menu => (
          <div className={styles['supplier-select-root']}>
            {menu}
            <div className="clear-all" onClick={() => onLanguageChange?.(undefined)}>
              {t`features_home_component_all_games_index_ss7jxrrh8s`}
            </div>
          </div>
        )}
        onVisibleChange={(visible: boolean) => onVisibleChange(visible)}
        onChange={v => onLanguageChange?.(v)}
      >
        {Object.keys(I18nsMap).map(option => (
          <Select.Option key={option} value={option}>
            {I18nsMap[option]}
          </Select.Option>
        ))}
      </Select>
    )
  }

  const onRenderSort = () => {
    return (
      <Select
        trigger="click"
        bordered={false}
        placeholder={t`features_home_component_all_games_index_dg7eo9it6l`}
        className="header-select sort"
        value={sortValue}
        onChange={v => onSortChange?.(v)}
        dropdownMenuClassName={styles['header-select-dropdown']}
        // prefix={<Icon name="icon_order" className="select-prefix" />}
        arrowIcon={<Icon name="icon_arrow_down" className="text-xs text-icon_color_02" />}
        onVisibleChange={(visible: boolean) => onVisibleChange(visible)}
      >
        {options.map(option => (
          <Select.Option key={option?.value} value={option?.value}>
            {option?.name}
          </Select.Option>
        ))}
      </Select>
    )
  }

  const onGetHeaderLayout = () => {
    return (
      {
        [TitleTypeEnum.category]: (
          <div className="header-wrap" onClick={() => onLoadAll?.()}>
            <div className="header-cell">
              {isImage ? (
                <Image src={titleIcon} preview={false} className="game-image" />
              ) : (
                <Icon name={titleIcon as string} className="game-icon" />
              )}
              <span className="game-name">{title || ''}</span>
            </div>
          </div>
        ),
        [TitleTypeEnum.filterAll]: (
          <div className="header-wrap all">
            <div className="flex items-center">
              <Button className="header-all-btn" onClick={() => link('/recreation/game-provider')}>
                {t`features_home_component_all_games_index_h7ucpps0sq`}
              </Button>
              {showLanguage && onRenderLanguage()}
            </div>

            {onRenderSort()}
          </div>
        ),
        [TitleTypeEnum.filterSupplier]: (
          <div className="header-wrap all">
            <div className="flex items-center">
              <Select
                ref={supplierSelectRef}
                mode="multiple"
                bordered={false}
                trigger="hover"
                triggerProps={{
                  position: 'bottom',
                  autoFitPosition: false,
                }}
                prefix={<Icon name="filter_icon" className="select-prefix" />}
                arrowIcon={<Icon name="icon_arrow_down" className="text-xs text-icon_color_02" />}
                placeholder={t`features_layout_content_compnent_layout_menu_index_00f9dlrqie`}
                className="header-select"
                dropdownMenuClassName={classNames(styles['header-select-dropdown'], 'supplier')}
                dropdownRender={menu => (
                  <div className={styles['supplier-select-root']}>
                    {menu}
                    <div className="clear-all" onClick={() => onAllClear()}>
                      {t`features_home_component_all_games_index_lvh7herqvk`}
                    </div>
                  </div>
                )}
                triggerElement={
                  <div className="supplier-select-wrap">
                    {/* <Icon name="filter_icon" className="select-prefix" /> */}
                    <div className="select-content">
                      <span className="supplier-text">
                        {t`features_layout_content_compnent_layout_menu_index_00f9dlrqie`}
                      </span>
                      {(supplierCurrentSelect || supplierSelect)?.length >= 1 && (
                        <div className="supplier-num">{(supplierCurrentSelect || supplierSelect)?.length}</div>
                      )}
                    </div>
                    <Icon name="icon_arrow_down" className="text-xs text-icon_color_02" />
                  </div>
                }
                onVisibleChange={(visible: boolean) => onVisibleChange(visible)}
              >
                {supplierInfo?.map(option => {
                  const hasProperty = (supplierCurrentSelect || supplierSelect).some(
                    item => item?.supplierId === option?.supplierId
                  )
                  return (
                    <div
                      key={option?.supplierId}
                      className="supplier-select-cell !hover:bg-bg_color_03"
                      onClick={() => {
                        const newtSupplierSelect = hasProperty
                          ? (supplierCurrentSelect || supplierSelect).filter(
                              item => item.supplierId !== option?.supplierId
                            )
                          : [...(supplierCurrentSelect || supplierSelect), option]
                        setSupplierSelect(newtSupplierSelect)
                        onSupplierChange?.(newtSupplierSelect)
                      }}
                    >
                      {hasProperty ? (
                        <div className="drop-select-wrap">
                          <Icon name="icon_drop_select" className="selected-icon" />
                          <Icon name="login_verify_unselected" className="unselected-icon" />
                        </div>
                      ) : (
                        <Icon name="login_verify_unselected" className="unselected-icon" />
                      )}

                      <div className="supplier-select-content">
                        <span className="supplier-select-label">{option?.supplierName}</span>
                        <div className={classNames('supplier-select-tag', 'ml-1', hasProperty && 'selected-tag-bg')}>
                          {option?.gameCounts}
                        </div>
                      </div>
                    </div>
                  )
                })}
                {supplierInfo?.length ? null : (
                  <NoDataImage
                    hasTheme={false}
                    whetherManyBusiness={false}
                    type={Type.png}
                    name={'image_default_graph'}
                    className="empty-wrap"
                  />
                )}
              </Select>
              {showLanguage && onRenderLanguage()}
            </div>

            {onRenderSort()}
          </div>
        ),
      }[titleType] || null
    )
  }

  const onGetFooterLayout = () => {
    return (
      {
        [PaginationTypeEnum.all]: (
          <div className="footer-wrap">
            <Button className="all-btn" onClick={() => onLoadAll?.()} loading={loading}>
              {t({
                id: 'features_home_component_all_games_index_lb7oq_7mp0',
                values: { 0: total || 0, 1: title || '' },
              })}
            </Button>
          </div>
        ),
        [PaginationTypeEnum.more]: (
          <div className="footer-wrap mt-2">
            <Progress
              percent={((data?.length || 0) / (total || 0)) * 100}
              width="15%"
              showText={false}
              trailColor={`var(--bg_sr_color)`}
              color={`var(--brand_color)`}
              strokeWidth={2}
            />
            <span className="progress-text">
              {t({
                id: 'features_home_component_all_games_index_hahviybfsy',
                values: { 0: total, 1: data?.length || 0 },
              })}
            </span>
            {isShowButton ? (
              <Button className="progress-btn" onClick={onLoadMore} loading={loading} disabled={data?.length === total}>
                {t`features_home_component_all_games_index_xolvlimdhf`}
              </Button>
            ) : null}
          </div>
        ),
        [PaginationTypeEnum.pagination]: (
          <div className="footer-wrap">
            <div className="pve-wrap">
              <div className="pagination-text mr-4" onClick={onLoadPrev}>
                {t`features_home_component_all_games_index__cgx8vgj5m`}
              </div>
              <div className="pagination-text" onClick={onLoadNext}>
                {t`features_home_component_all_games_index_b2l4ho6fih`}
              </div>
            </div>
          </div>
        ),
      }[footerType] || null
    )
  }

  useEffect(() => {
    setSupplierSelect([])
  }, [supplierInfo])

  return (
    <div className={classNames(styles['all-games-root'], `${theme === ThemeEnum.dark ? 'dark-spin' : 'light-spin'}`)}>
      {showSearch && <GamesSearch />}

      {showTitle ? onGetHeaderLayout() : null}

      <Spin
        loading={isLoading}
        className={classNames('spin-wrap', {
          provider: isLoading || (data && data?.length > 0 && type === RaGameTypeEnum.provider),
          other: isLoading || (data && data?.length > 0 && type !== RaGameTypeEnum.provider),
        })}
      >
        {!isLoading && !data?.length ? (
          renderNoImage || (
            <NoDataImage
              hasTheme
              whetherManyBusiness={false}
              type={Type.png}
              name={'image_default_graph'}
              className="empty-wrap"
            />
          )
        ) : (
          <div className="list-wrap" id="list-wrap">
            {data?.map((item, i) => {
              switch (type) {
                case RaGameTypeEnum.provider:
                  return (
                    <SupplierGame
                      data={item}
                      className="game-cell supplier"
                      onEnter={v => onEnterChange?.(v)}
                      key={`${item?.id ? `${item?.id}${i}` : `${item?.groupId}${i}`}`}
                    />
                  )
                default:
                  return (
                    <GameCell
                      data={item}
                      className="game-cell other"
                      onEnter={v => onEnterChange?.(v)}
                      key={`${item?.id ? `${item?.id}${i}` : `${item?.groupId}${i}`}`}
                      noShowOnlineUsers={noShowOnlineUsers}
                    />
                  )
              }
            })}
          </div>
        )}
      </Spin>

      {showFooter && data && data?.length > 0 ? onGetFooterLayout() : null}
    </div>
  )
}

export default memo(AllGames)
