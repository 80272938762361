import { systemThemeColor } from '@/helper/env'
import { InitThemeColor } from '@/constants/base'
import { IGamesSearchProps } from '@/features/recreation/component/games-search'
import GamesSearch2z from '@/features/recreation/theme/2z/games-search'
import GamesSearchOk from '@/features/recreation/theme/ok/games-search'

export default function GamesSearch(props: IGamesSearchProps) {
  switch (systemThemeColor) {
    case InitThemeColor['2z']:
      return <GamesSearch2z {...props} />
    case InitThemeColor.ok:
      return <GamesSearchOk {...props} />
    default:
      return <GamesSearch2z {...props} />
  }
}
