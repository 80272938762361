/**
 * 搜索
 */
import { t } from '@lingui/macro'
import { link } from '@/helper/link'
import { useEffect, useState, useRef, useMemo, useLayoutEffect, ReactNode } from 'react'
import Icon from '@/components/icon'
import { useCommonStore } from '@/store/common'
import { getGamesRoutePath, getPopularGameRoutePath } from '@/helper/route/game'
import { Input, Select, Spin } from '@nbit/arco'
import { useDebounce, useUpdateEffect, useRequest, useInViewport, useSize } from 'ahooks'
import { RaHallGameListProps } from '@/typings/api/game'
import { getHallGameHot, getGameTypeData } from '@/apis/game'
import { useUserStore } from '@/store/user'
import { getSearchListMediaQuery } from '@/helper/game/home'
import { RefInputType } from '@nbit/arco/es/Input'
import { useLayoutStore } from '@/store/layout'
import GameCell from '@/features/recreation/theme/components/game-cell'
import LazyImage from '@/components/lazy-image'
import { oss_ra_image_domain_address } from '@/constants/oss'
import classNames from 'classnames'
import styles from './index.module.css'

/** 针对 SSR 的 hack 写法 */
const useIsomorphicLayoutEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect

enum GameDefaultProps {
  none,
  one,
  maxHotNum = 6,
  hotMaxNum = 21,
  maxNum = 39,
}

export interface IGamesSearchProps {
  /** 输入框样式 */
  inputClass?: string
  /** 下拉框样式 */
  popupClass?: string
  /** 距离左边宽度 */
  leftWidth?: number
  /** 是否展示热门搜索 */
  showHotSearch?: boolean
  /** 是否展示无数据图片 */
  showNoDataImg?: boolean
  /** 是否展示清除搜索历史图标 */
  showDelHistoryIcon?: boolean
  /** 自定义清除搜索历史 dom */
  delHistoryNode?: ReactNode
  /** 搜索历史展示条数 */
  historyNum?: number
}

function CommonGamesSearch(props: IGamesSearchProps) {
  const {
    inputClass,
    popupClass,
    leftWidth = 0,
    showHotSearch = false,
    showNoDataImg = false,
    showDelHistoryIcon = false,
    delHistoryNode,
    historyNum = 5,
  } = props || {}
  const { userInfo: { uid } = {} } = useUserStore()
  const { searchHistory, setSearchHistory, theme } = useCommonStore()
  const { contentDom } = useLayoutStore()

  const gameRef = 'game_ref'
  const newUid = uid || 'system'

  const [searchKey, setSearchKey] = useState<string>('') // 搜索关键字
  const [total, setTotal] = useState<number>(GameDefaultProps.none)
  const [gameHotData, setGameHotData] = useState<RaHallGameListProps[]>([]) // 热门推荐列表
  const [gamesData, setGamesData] = useState<RaHallGameListProps[]>([]) // 搜索游戏列表
  const [pageCollect, setPageCollect] = useState<{ pageNum: number; pageSize: number }>({
    pageNum: GameDefaultProps.one,
    pageSize: GameDefaultProps.maxNum,
  })
  const [showOverlay, setShowOverlay] = useState(false) // 是否展示遮罩层（解决下拉框调起后滚动页面导致样式错位问题）
  const [showNoData, setShowNoData] = useState<boolean>(false) // 是否展示无数据
  const [historyList, setHistoryList] = useState<string[]>(searchHistory[newUid] || []) // 搜索历史列表
  const [isFocused, setIsFocused] = useState(false) // 是否获取焦点

  const searchRef = useRef<HTMLDivElement>(null)
  const noSearchRef = useRef(null)
  const inputRef = useRef<RefInputType>(null)
  const bodyRef = useRef<HTMLElement>()
  const inputSize = useSize(bodyRef)
  const [showBottom] = useInViewport(searchRef)
  const debouncedValue = useDebounce(searchKey, { wait: 300 })

  /** 搜索游戏 */
  const getGamesSearch = async () => {
    const params = {
      name: searchKey,
      pageNum: pageCollect?.pageNum,
      pageSize: pageCollect?.pageSize,
    }
    const { data, isOk } = await getGameTypeData(params)
    if (isOk && data) {
      setTotal(data?.total)
      const games = [...gamesData, ...(data?.list || [])]
      setGamesData(games)
      setShowNoData(games.length === 0)

      if (data?.list?.length === 0) return

      const newHistoryList = searchHistory[newUid] || []

      const isExist = newHistoryList?.some(item => item === debouncedValue)
      if (!isExist) {
        const newSearchHistory = {
          ...searchHistory,
          [newUid]: [debouncedValue, ...newHistoryList].slice(0, historyNum),
        }
        setSearchHistory(newSearchHistory)
        setHistoryList(newSearchHistory[newUid])
      }
    }
  }

  /** 游戏卡片点击事件 */
  const onEnterChange = v => {
    if (contentDom) {
      contentDom.style.overflowY = 'auto'
    }

    if (v?.isGroup) {
      const groupId = v?.groupId as number
      return link(getGamesRoutePath(groupId))
    }
    const ids = v.id as number
    const groupId = v?.groupId as number
    return link(getGamesRoutePath(ids, groupId))
  }

  const { run, loading } = useRequest(getGamesSearch, { manual: true, throttleWait: 300, debounceWait: 300 })

  /** 查询热门推荐列表 */
  const getHallGameHotData = async () => {
    const params = { ...pageCollect, pageSize: GameDefaultProps.maxHotNum }
    const { data, isOk } = await getHallGameHot(params)
    if (isOk && data) {
      setGameHotData(params?.pageNum === 1 ? data?.list : [...gameHotData, ...(data?.list || [])])
    }
  }

  /** 清除搜索记录 */
  const onDelHistory = () => {
    setHistoryList([])
    setSearchHistory({ ...searchHistory, [newUid]: [] })
  }

  /** 监听搜索输入框变化 */
  const changeSearchKey = (value: string) => {
    setSearchKey(value)
    setShowNoData(false)
  }

  /** 计算下拉框高度 */
  const calculatePopUpHeight = () => {
    try {
      const { rows, gap } = getSearchListMediaQuery()
      const inputDom = inputRef.current?.dom
      const dataLen = gamesData?.length ?? 0
      const moreThanTwoRows = dataLen > rows * 2
      if (!inputDom || !moreThanTwoRows) return 'auto'

      /** 14：搜索图标宽度 */
      const inputWidth = inputDom.offsetWidth + 14
      const cardWidth = (inputWidth - gap * (rows - 1)) / rows

      /** 35 / 46：卡片宽高比 */
      const cardHeight = (cardWidth * 46) / 35

      /** 24：在玩人数高度 40：上下 padding */
      return (cardHeight + 24) * 2 + 40
    } catch {
      return 'auto'
    }
  }

  /** 计算搜索下拉框最大高度 */
  const maxHeight = useMemo(() => {
    return calculatePopUpHeight()
  }, [gamesData, inputSize])

  /** 渲染游戏列表 */
  const onRenderGames = (gameList, className: string) => {
    if (gameList?.length === 0) return null

    return (
      <div className={className}>
        {gameList?.map(game => {
          return (
            <GameCell
              id={gameRef}
              className="game-cell other"
              key={game?.id}
              data={game}
              onEnter={v => onEnterChange?.(v)}
            />
          )
        })}
      </div>
    )
  }

  useEffect(() => {
    showHotSearch && getHallGameHotData()
  }, [showHotSearch])

  useEffect(() => {
    if (gamesData?.length >= total) return
    setPageCollect({
      pageNum: gamesData?.length === 0 ? GameDefaultProps.one : pageCollect.pageNum + GameDefaultProps.one,
      pageSize: GameDefaultProps.maxNum,
    })
  }, [showBottom])

  useIsomorphicLayoutEffect(() => {
    bodyRef.current = document.body
  }, [])

  useUpdateEffect(() => {
    setGamesData([])
    debouncedValue &&
      debouncedValue?.length >= 2 &&
      setPageCollect({
        pageNum: GameDefaultProps.none,
        pageSize: GameDefaultProps.maxNum,
      })
  }, [debouncedValue])

  useUpdateEffect(() => {
    if (!pageCollect.pageNum) {
      setPageCollect({
        pageNum: GameDefaultProps.one,
        pageSize: GameDefaultProps.maxNum,
      })
      return
    }
    if (debouncedValue && debouncedValue?.length >= 2) {
      run()
    }
  }, [pageCollect])

  useEffect(() => {
    setHistoryList(searchHistory[newUid] || [])
  }, [newUid])

  return (
    <>
      {showOverlay && <div className={styles['games-search-overlay']}></div>}
      <Select
        trigger={['focus']}
        triggerProps={{
          position: 'bottom',
          autoFitPosition: false,
        }}
        triggerElement={
          <Input
            ref={inputRef}
            placeholder={t`help.center.support_02`}
            value={searchKey}
            onChange={changeSearchKey}
            className={classNames(styles['games-search-input'], inputClass, {
              'focused-search-input': isFocused,
            })}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            prefix={<Icon name="icon_search" className="text-text_color_04" />}
          />
        }
        onVisibleChange={visible => {
          setShowOverlay(visible)
          if (contentDom) {
            contentDom.style.overflowY = visible ? 'hidden' : 'auto'
          }
        }}
      >
        <div
          className={classNames(styles['games-search-wrap'], popupClass)}
          style={{ maxHeight, left: `-${leftWidth}px` }}
        >
          {gamesData?.length === 0 ? (
            <>
              <div className="flex flex-col" ref={noSearchRef}>
                {debouncedValue?.length >= 2 ? (
                  loading ? (
                    <Spin loading={loading} className="search-loading" />
                  ) : (
                    showNoData && (
                      <div className="search-empty-wrap">
                        {showNoDataImg && (
                          <LazyImage
                            src={`${oss_ra_image_domain_address}image_default_graph_${theme}.png`}
                            width={104}
                            height={92}
                          />
                        )}
                        <div className="search-empty-text">{t`features_home_component_games_search_index_xldf3pzf_f`}</div>
                      </div>
                    )
                  )
                ) : (
                  <div className="empty-text">
                    {t`features_home_component_games_search_index_vg8ohgwfed`} 2{' '}
                    {t`features_home_component_games_search_index_ygsae034xz`}
                  </div>
                )}

                {historyList?.length > 0 && (
                  <div className="history-wrap">
                    <div className="history-header">
                      <div className="history-title">{t`features_home_component_games_search_index_race0jeqrn`}</div>
                      {delHistoryNode || (
                        <div className="del-wrap" onClick={() => onDelHistory()}>
                          {showDelHistoryIcon && <Icon name="icon_clear" className="del-icon" />}
                          <div>
                            {t`features_home_component_games_search_index_lv29bsuwwt`} (
                            {historyList?.length || GameDefaultProps.none})
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="history-list">
                      {historyList?.map(history => {
                        return (
                          <div className="history-cell" key={history} onClick={() => changeSearchKey(history)}>
                            <span className="history-name">{history}</span>
                            <Icon
                              name="icon_delete"
                              className="delete-icon"
                              onClick={e => {
                                e.stopPropagation()
                                const newHistoryList = searchHistory[newUid].filter(item => item !== history)
                                const newSearchHistory = { ...searchHistory, [newUid]: newHistoryList }
                                setSearchHistory(newSearchHistory)
                                setHistoryList(newHistoryList)
                              }}
                            />
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )}

                {showHotSearch && (
                  <div className="history-header">
                    <div className="flex items-center">
                      <Icon name="icon_fire_v2" className="hot-icon" />
                      <span className="history-title">{t`features_home_popular_index_5qbjl0slkz`}</span>
                    </div>
                    <label
                      onClick={() => {
                        if (contentDom) {
                          contentDom.style.overflowY = 'auto'
                        }
                        link(getPopularGameRoutePath())
                      }}
                    >{t`features/message-center/messages-3`}</label>
                  </div>
                )}
              </div>

              {showHotSearch && onRenderGames(gameHotData, 'history-list mt-4')}
            </>
          ) : (
            <Spin loading={loading}>
              {onRenderGames(gamesData, 'search-list')}
              <div ref={searchRef} className="search-bottom" />
            </Spin>
          )}
        </div>
      </Select>
    </>
  )
}

export { CommonGamesSearch }
